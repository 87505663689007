import React from "react";
import './cover.css'
import characters from '../../assets/img/characters.png'
import logo from '../../assets/img/logo-bg.png'
import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { PlayCircle } from "@mui/icons-material";

function Cover() {
    const clickHome = () => {
        setTimeout(() => {
            window.location.hash = "#jugar";
        }, 50);
    }
    return (
        <React.Fragment>
            <div className="greetingcontainer " >
                <div className="cover-effect-container" data-aos="zoom-in" data-aos-delay='200'>
                    <div className="char-container" >
                        <img src={characters} className="characters" />
                    </div>
                    <div className="greeting" data-aos="fade-left" data-aos-delay='200'>
                        <img src={logo} className="logo-image" /><br />
                        <span className="text-bg">VIVE LA VERDADERA EXPERIENCIA EN ROLEPLAY</span>
                        <NavLink className="button-cover" key='0' to='/' onClick={clickHome}><PlayCircle style={{ fontSize: '2.3rem', position: 'relative', top: '5px', right: '5px' }} />Jugar</NavLink>
                    </div>
                </div>
            </div>

        </React.Fragment >


    )
}

export default Cover;