import React from "react";
import './shop.css';
import Card from "../card/card";
import { useParams, redirect } from "react-router-dom";
import shopCoin1 from '../../assets/img/shop-coin1.png';
import shopCoin2 from '../../assets/img/shop-coin2.png';
import shopCoin3 from '../../assets/img/shop-coin3.png';
import Swal from 'sweetalert2'

function Shop() {
    let { name } = useParams();
    let title = 'Adquiere tus CL Coins en un minuto';
    sessionStorage.setItem('name', name);

    const derivarCompra = () => {

    }
    const solicitarNombre = () => {
        <redirect to="/tienda/:name" />
    }
    function clickButton() {
        Swal.fire({
            title: '¡Ups!',
            text: 'Esta página aún está en construcción',
            icon: 'info',
            background: '#3f3f3f',
            color: '#ffffff',
            confirmButtonText: 'Aceptar'
        })
    }
    return (
        <div className="shopcontainer">
            <div className="shop-box-container" data-aos="fade-right">
                <div className="title-container">
                    <span className="title-section">{title}</span>
                </div>
                <div className="shop-cards-container">
                    <Card className='shop-card' title=" " image={shopCoin1} functionClick={clickButton} footerText='5 USD' description="1000 CL Coins" cardStyle={{ 'marginRight': '1rem' }} button="Recargar" />
                    <Card className='shop-card' title=" " image={shopCoin2} functionClick={clickButton} footerText='13 USD' description="3000 CL Coins" cardStyle={{ 'marginRight': '1rem' }} button="Recargar" />
                    <Card className='shop-card' title=" " image={shopCoin3} functionClick={clickButton} footerText='42 USD' description="10000 CL Coins" button="Recargar" />
                </div>
            </div>
        </div>
    )

}

export default Shop;