import React from "react";
import './development.css'
import developchar from '../../assets/img/developchar.png'
import { redirect } from '../../utils/utils'

function Development() {
    return (
        <div className="development">
            <div className="texto" data-aos="fade-right" data-aos-delay='200'>
                <h1 className="title">FORMA PARTE DE NUESTRO EQUIPO DE DESARROLLO</h1>
                <span className="develop-desc">En Ciudad Latina siempre estamos en busca de nuevos talentos. Si sientes que eres uno de ellos, ¡serás más que bienvenido!</span>
                <button className="develop-btn" onClick={() => redirect('https://ciudadlatina.cc/discord')}>Anotarse</button>
            </div>
            <div className="divp">
                <img src={developchar} data-aos="fade-left" data-aos-delay='200' className="char-develop"/>
            </div>

            
        </div>

    )
}

export default Development;