import React from "react";
import './organization.css'
import organizationchar from '../../assets/img/organization-char.png'

function Organization() {
    return (
        <div className="organization">
            <div className="divp">
                <img src={organizationchar} data-aos="fade-right" data-aos-delay='200' className="char-develop"/>
            </div>
            <div className="texto" data-aos="fade-left" data-aos-delay='200'>
                <h1 className="title">CREA TU PROPIA ORGANIZACIÓN</h1>
                <span className="develop-desc">¿Tienes habilidades de liderazgo y eres bueno para los negocios?
                    Arma una organización, lleva los números y asegúrate de lavar los fondos de forma apropiada, lo último
                    que quieres es terminar tras las rejas.
                </span>
            </div>
            

            
        </div>

    )
}

export default Organization;