import './App.css';
import Navbar from './components/navbar/navbar'
import Cover from './components/cover/cover';
import Play from './components/play/play';
import Development from './components/development/development';
import React from 'react';
import Organization from './components/organization/organization';
import Footer from './components/footer/footer';
import Custom from './components/custom/custom';
import Shop from './components/shop/shop';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from './utils/scrolltotop';
import Forum from './components/forum/forum';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <div className='page-body'>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path='/tienda' element={<Shop />} />
            <Route path='/tienda/gtav/:name' element={<Shop />} />
            <Route path='/foro' component={() => {
              window.location.href = 'https://ciudadlatina.gg';
              return null;
            }} />
            <Route path='/' element={
              <React.Fragment>
                <Cover />
                <Development />
                <Play />
                <Organization />
                <Custom />
              </React.Fragment>
            }>
            </Route>

          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
