import React from "react";
import './card.css'

function Card({title, description, image, cardStyle, button, footerText, functionClick}) {
    return (
        <div className="cardcontainer" style={cardStyle} data-aos="zoom-in" data-aos-delay='200'>
            <span className="cardtitle">{title}</span>
            <span className="description">{description}</span>
            <img src={image} className="image" />
            <span className="card-footer-text">{footerText}</span>
            <div className="div-btn">
            <button onClick={functionClick} className="btn">{button}</button>
            </div>
            
        </div>
    )
}

export default Card;