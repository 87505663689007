import React from "react";
import './play.css'
import Card from '../card/card'
import ragemp from '../../assets/img/ragemp.png'
import gtav from '../../assets/img/gtav.png'
import logo from '../../assets/img/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Play() {
    function clickButton() {
        navigator.clipboard.writeText('server.ciudadlatina.cc');
        toast.success('Ya copiaste la IP, colocala en RAGE para jugar!', {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    function redirect() {
        window.location.href = 'https://store.steampowered.com/app/271590/Grand_Theft_Auto_V/';
    }
    function rageDownload() {
        window.location.href = 'https://cdn.rgsvc.io/public/files/RAGEMultiplayer_Setup.exe';
    }

    return (
        <section id="jugar">
            <div className="playcontainer">
                <Card title="Paso 1" description="Compra el juego original por Steam" image={gtav} button="Descargar" functionClick={redirect} className='play-card' />
                <Card title="Paso 2" description="Descarga e instala RAGE-MP" image={ragemp} button="Descargar" functionClick={rageDownload} className='play-card' />
                <Card title="Paso 3" description="Conectate a Ciudad Latina" image={logo} button="Copiar IP" functionClick={clickButton} className='play-card' />
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </section>

    )
}

export default Play;