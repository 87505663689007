import React from "react";
import './navbar.css'
import logo from '../../assets/img/logo.png'
import { Forum, PlayCircle, Star } from "@mui/icons-material";
import discord from '../../assets/icons/discord.svg'
import { redirect, sAlert } from '../../utils/utils'
import FadeMenu from "../mobile-menu/mobile-menu";
import { NavLink } from 'react-router-dom';


function Navbar() {
    const clickHome = () => {
        setTimeout(() => {
            window.location.hash = "#jugar";
        }, 50);
    }
    return (
        <div className="navcontainer" id='nav'>
            <div className="menu">
                <img src={logo} className="logo-img" />
            </div>
            <div className="navbuttons">
                <NavLink className="button-nav" key='0' to='/' onClick={clickHome}><PlayCircle style={{ fontSize: '1.3rem', position: 'relative', top: '5px', right: '2px' }} />Jugar</NavLink>
                <NavLink className="button-nav" key='1' onClick={() => redirect('https://ciudadlatina.cc')}><Forum style={{ fontSize: '1.3rem', position: 'relative', top: '5px', right: '2px' }} />Foro</NavLink>
                <NavLink className="button-nav" key='2' to='/tienda'><Star style={{ fontSize: '1.3rem', position: 'relative', top: '5px', right: '2px' }} />Tienda</NavLink>
                <NavLink className="button-nav" key='3' onClick={() => redirect('https://ciudadlatina.cc/discord')}><img className="discord-icon" src={discord} /> Discord</NavLink>

            </div>
            <div className="menu-mobile">
                <FadeMenu className='only-mobile' />
            </div>
        </div>
    )
}


export default Navbar;