import React from "react";
import './footer.css'
import { PlayCircle, Forum, Star, Instagram, YouTube, Facebook } from "@mui/icons-material";
import logo from '../../assets/img/logo.png';
import discord from '../../assets/icons/discord.svg'
import { redirect, sAlert } from '../../utils/utils'

function Footer() {

    return (
        < React.Fragment >
            <div className="footer-background">
                <div className="footer" data-aos="zoom-in">
                    <div className="footer-img-cont">
                        <img src={logo} className="footer-img" />
                    </div>
                    <div className="social-buttons-container">
                        <a href="#jugar" className="button-social-footer"><Instagram fontSize='large' style={{ position: 'relative', top: '5px', right: '2px' }} /></a>
                        <a href="#jugar" className="button-social-footer"><YouTube fontSize='large' tyle={{ position: 'relative', top: '5px', right: '2px' }} /></a>
                        <a href="#jugar" className="button-social-footer"><Facebook fontSize='large' style={{ position: 'relative', top: '5px', right: '2px' }} /></a>
                        <a className="button-social-footer" onClick={() => redirect('https://ciudadlatina.net/discord')}><img className="discord-icon" src={discord} /></a>
                    </div>
                    <div className="footer-links" data-aos="zoom-in">
                        <a href="#jugar" className="button-nav-footer"><PlayCircle style={{ position: 'relative', top: '5px', right: '6px' }} />Jugar</a>
                        <a onClick={() => sAlert('info', '¡Ups!', 'Esta página todavía está en construcción, ¡mil disculpas!')} className="button-nav-footer"><Forum style={{ position: 'relative', top: '5px', right: '6px' }} />Foro</a>
                        <a onClick={() => sAlert('info', '¡Ups!', 'Esta página todavía está en construcción, ¡mil disculpas!')} className="button-nav-footer"><Star style={{ position: 'relative', top: '5px', right: '6px' }} />Tienda</a>

                    </div>
                </div>
                <div className="footer-text-container">
                    <small className="footer-text">Copyright © Ciudad Latina 2022-2024</small>
                    <small className="footer-text">Todos los derechos reservados</small>
                </div>
                
            </div>
        </React.Fragment >
    )
}
export default Footer;