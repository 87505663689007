import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MenuIcon from '@mui/icons-material/Menu';
import discord from '../../assets/icons/discord.svg'
import { Forum, PlayCircle, Star } from "@mui/icons-material";
import { redirect, redirectSection, sAlert } from '../../utils/utils';

export default function FadeMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const styleBtn = {
        color: '#ffffff'
    }
    const styleMenu = {
        backgroundColor: '#2f2f2f'
    }
    return (
        <React.Fragment>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={styleBtn}
            >
                <MenuIcon />
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                style={{ color: '#2f2f2f', fontFamily: 'Poppins' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}

            >
                <MenuItem onClick={() => { redirectSection('jugar'); handleClose() }}><PlayCircle style={{ position: 'relative', right: '4px' }} />Jugar</MenuItem>
                <MenuItem onClick={() => { sAlert('info', '¡Ups!', 'Esta página todavía está en construcción, ¡mil disculpas!'); handleClose() }}><Forum style={{ position: 'relative', right: '4px' }} />Foro</MenuItem>
                <MenuItem onClick={() => { sAlert('info', '¡Ups!', 'Esta página todavía está en construcción, ¡mil disculpas!'); handleClose() }}><Star style={{ position: 'relative', right: '4px' }} />Tienda</MenuItem>
                <MenuItem onClick={() => { redirect('https://ciudadlatina.net/discord'); handleClose() }}><a style={{  }} onClick={() => redirect('https://ciudadlatina.net/discord')}><img src={discord} style={{width: '1.2rem', marginRight: '0.1rem', color:'#2f2f2f'}} /> Discord</a></MenuItem>
            </Menu>
        </React.Fragment>
    );
}