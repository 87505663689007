import React from "react";
import './custom.css'
import customCar from '../../assets/img/custom-car.png'

function Custom() {
    return (
        <div className="custom-container">
            <div className="texto" data-aos="fade-right" data-aos-delay='200'>
                <h1 className="title">CUSTOMIZÁ TUS COSAS</h1>
                <span className="develop-desc">Podrás darle tu impronta a absolutamente todo: a tu personaje con sus prendas, tatuajes, cicatrices, etc; tus vehículos, casas, negocios y más.
                Te sorprenderá descubrir todo lo que tenemos preparado para tí.
                </span>
            </div>
            <div className="divp">
                <img src={customCar} data-aos="fade-left" data-aos-delay='200' className="custom-car"/>
            </div>

            
        </div>

    )
}

export default Custom;