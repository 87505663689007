// Funciones y variables útiles

import React from 'react';
import Swal from 'sweetalert2'

export const redirect = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
}
export const redirectSection = (section) => {
    window.location.href = '#' + section;
}
export const sAlert = (type, title, text) => {
    Swal.fire({
        title: title,
        text: text,
        icon: type,
        background: '#3f3f3f',
        color: '#ffffff',
        confirmButtonText: 'Aceptar'
    })
}